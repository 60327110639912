import {Floorplan} from "../../../api/apiClient";
import {useState} from "react";
import {Box, CircularProgress, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {BrowserFloorMap} from "./BrowserFloorMap";
import {Close} from "@mui/icons-material";
import {calculateLinearInterpolation} from "../../common";


export interface BrowserMinimapProps {
    floorPlans: Floorplan[];
    currentPanorama: string;
    changePanorama: (panorama: string) => void;
    hideMinimap: () => void;
}

const solveMinimapWidth = (screenAspectRatio: number) => {

    const maxRatioForDynamic = 1.3;
    const minRatioForDynamic = 0.8;
    const maxAddition = 50;
    const minAddition = 0;

    // 80 is the absolute maximum the height can be, calculate how much less it should be based on image aspect ratio. The wider the image, the shorter the minimap should be.
    const addition = calculateLinearInterpolation(screenAspectRatio, minRatioForDynamic, maxRatioForDynamic, minAddition, maxAddition);
    return 30 + addition;
}

const solveMinimapHeight = (imageAspectRatio: number, screenVerticallyLarge: boolean) => {

    const maxRatioForDynamic = 1;
    const minRatioForDynamic = 0.5;
    const maxReduction = 20;
    const minReduction = 0;

    // 80 is the absolute maximum the height can be, calculate how much less it should be based on image aspect ratio. The wider the image, the shorter the minimap should be.
    const reduction = calculateLinearInterpolation(imageAspectRatio, minRatioForDynamic, maxRatioForDynamic, minReduction, maxReduction);
    const maxMinimapHeight = 80 - reduction;

    return screenVerticallyLarge ? maxMinimapHeight : maxMinimapHeight + 7;
}


export function BrowserMinimap(props: BrowserMinimapProps) {
    const {currentPanorama, changePanorama, floorPlans, hideMinimap} = props;

    const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
    const [prevPanorama, setPrevPanorama] = useState("");
    const [isFloorPlanImageLoading, setIsFloorPlanImageLoading] = useState(true);

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const screenVerticallyLarge = isLargeScreen || isVerySmallScreen;


    const handleFloorPlanChange = (newFloorPlanIndex: number) => {
        setSelectedFloorIndex(newFloorPlanIndex);
        setIsFloorPlanImageLoading(true);
    }

    // Possible floor change when panorama changes
    if (currentPanorama !== prevPanorama) {
        const foundFloorPlanIndex = findCurrentFloorIndex();
        if (foundFloorPlanIndex >= 0 && selectedFloorIndex !== foundFloorPlanIndex) {
            handleFloorPlanChange(foundFloorPlanIndex);
        }

        setPrevPanorama(currentPanorama);
    }

    const currentFloorPlan = floorPlans[selectedFloorIndex];

    if (!currentFloorPlan) {
        return <></>;
    }

    const imageAspectRatio = currentFloorPlan.imageWidth/currentFloorPlan.imageHeight;
    const screenAspectRatio = window.innerWidth/window.innerHeight;

    // If aspect ratio of window is smaller than that of the floor plan image, we should limit image width instead of height.
    // That's because the height limit won't take effect in that case, which causes the hotspot position calculation to be wrong because it is done based on the containing div size,
    // but image would be forced to shrink to fit the screen.
    const limitImageHeight = imageAspectRatio <= 1 && screenAspectRatio > imageAspectRatio;

    const minimapHeight = limitImageHeight ? solveMinimapHeight(imageAspectRatio, screenVerticallyLarge) : undefined;
    const minimapWidth = !limitImageHeight ? solveMinimapWidth(screenAspectRatio) : undefined;

    // Not accurate number, used to determine if the minimap is vertically large.
    const minimapHeightPixels = (minimapHeight ? window.innerHeight*(minimapHeight/100) : (minimapWidth ? window.innerWidth*(minimapWidth/100) / imageAspectRatio : 0));
    const minimapVerticallyLarge = minimapHeightPixels > 420;

    function findCurrentFloorIndex() {
        return floorPlans.findIndex((fp) =>
            fp.hotspots.some((hp) => hp.targetPanorama === currentPanorama))
    }


    return <div style={{zIndex: 1050, position:"relative", textAlign: "center", flexShrink: 0}}>
        <Paper elevation={5}
               sx={{
                   px: 2,
                   pt: screenVerticallyLarge ? 0 : 2,
                   marginTop: isVerySmallScreen ? 5 : 0,
                   height: minimapHeight ? minimapHeight + "vh" : undefined,
                   width: minimapWidth ? minimapWidth + "vw" : undefined,
                   position: "relative",
                   display: "inline-block"
               }}
               className={"fade-in"}
        >
            {/*Close minimap button*/}
            {screenVerticallyLarge && <div style={{top: -5, right: -20, position: "relative", textAlign: "right", flexShrink: 0, height: '10%'}}>
                <IconButton size={"large"} onClick={hideMinimap} sx={{color: theme.palette.text.primary}}>
                    <Close fontSize={"inherit"} color={"inherit"} />
                </IconButton>
            </div>}

            {/*Floorplan image*/}
            <div style={{
                height: screenVerticallyLarge ? "80%" : "90%",
                position: "relative",
                border: `5px solid ${theme.palette.primary.main}`,
                opacity: isFloorPlanImageLoading ? 0.5 : 1
            }}>
                <BrowserFloorMap changePanorama={changePanorama} floorPlan={currentFloorPlan}
                                 minimapHeightVH={minimapHeight} minimapWidthVW={minimapWidth}
                                 currentPanorama={currentPanorama} onFloorPlanImageLoaded={() => setIsFloorPlanImageLoading(false)} />

                {/*Loading symbol*/}
                {isFloorPlanImageLoading && <div style={{
                    position: "relative",
                    bottom: "65%",
                    left: "0%",
                    zIndex: 30,
                    display: "block"
                }}>
                    <CircularProgress size={"50%"} sx={{}} />
                </div>}
            </div>

            {/*Floor switch buttons*/}
            <div style={{flexShrink: 0, height: '10%', width: "100%",  margin: "auto",
                position: "relative", top: minimapVerticallyLarge ? "2%" : 0}}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <IconButton size={minimapVerticallyLarge ? "large" : "small"} sx={{color: theme.palette.text.primary}}
                                disabled={selectedFloorIndex <= 0}
                                onClick={() => handleFloorPlanChange(selectedFloorIndex - 1)}>
                        <ArrowBackIosIcon fontSize={"inherit"} color={"inherit"}/>
                    </IconButton>

                    <Typography
                        variant={minimapVerticallyLarge ? "h4" : "h6"}>{currentFloorPlan.imageHeader}</Typography>

                    <IconButton size={minimapVerticallyLarge ? "large" : "small"} sx={{color: theme.palette.text.primary}}
                                disabled={selectedFloorIndex >= floorPlans.length - 1}
                                onClick={() => handleFloorPlanChange(selectedFloorIndex + 1)}>
                        <ArrowForwardIosIcon fontSize={"inherit"} color={"inherit"}/>
                    </IconButton>
                </Stack>
            </div>
        </Paper>
    </div>
}