import React from "react";
// @ts-ignore
import {Entity} from 'aframe-react';
import {HotSpotGlow} from "./HotSpotGlow";
import {THREE} from "aframe";

export function FloorHotSpot(props: {id: string, position: THREE.Vector3, onClick: () => void, isVrMode: boolean}) {
    const {id, position, onClick, isVrMode} = props;

    const material = {flatShading: true, color: "white", side: "double", opacity: 1}; // opaque objects that are far from camera look bad for some reason in vr mode.
    const geometry = {primitive: "ring", radiusOuter: isVrMode ? 1.2 : 0.3, radiusInner: isVrMode ? 0.8 : 0.2};

    // Spots appear to be floating in VR mode if they are not moved farther down.
    const multiplier = isVrMode ? 4 : 1;
    const finalPosition = {x: position.x * multiplier, y: position.y * multiplier, z: position.z * multiplier};

    return <Entity id={id} key={id}
                   position={finalPosition}
                   proxy-event__showglow={`event: mouseenter; to: #glow-${id}; as: show`}
                   proxy-event__hideglow={`event: mouseleave; to: #glow-${id}; as: hide`}
                   proxy-event__fade={`event: click; to: #sky; as: fade`}
                   events={{click: onClick}}
                   class="raycastable panoramaHotspot"
                   geometry={{primitive: "circle", radius: isVrMode ? 1.2 : 0.3}}
                   material={{opacity: 0, side: "double"}}
                   rotation={"90 0 0"}
    >
        <Entity  position={"0 0 0.02"} id={"inner-" + id}
                 geometry={geometry}
                 material={material}
        />

        {/*The glow is a transparent image which is shown when mouse is hovering over the hotspot.*/}
        <HotSpotGlow id={id} width={isVrMode ? 6 : 1.5} height={isVrMode ? 6 : 1.5} position={new THREE.Vector3(0, 0.01, -0.01)} />
    </Entity>
}