// @ts-ignore
import {Scene} from 'aframe-react';
import React from 'react';
import {PanoramaTour} from "./api/apiClient";
import {AssetsManagement} from "./components/AssetsManagement";
import {ViewControl} from "./components/ViewControl";
import {startHouseTour} from "./components/common";

require('aframe');
require('aframe-event-set-component');
require('aframe-proxy-event-component');
require('aframe-look-at-component');
require('aframe-thumb-controls-component');
require('./animations/cameraZoom');
require('./components/ui/VR/layout');
require('./components/camera/rotationReader');
require('./components/ui/VR/menucontainer');

export interface PanoramaControlProps {
    tour: PanoramaTour;
    currentPanorama: string;
    startPanorama: string | null;
    changePanorama: (panorama: string) => void;
    setIsVrMode: (isVrMode: boolean) => void;
    isVrMode: boolean;
    sceneLoaded: () => void;
}

export function PanoramaScene(props: PanoramaControlProps) {
    const { tour, currentPanorama, startPanorama, changePanorama,
        setIsVrMode, isVrMode, sceneLoaded } = props;

    // @ts-ignore Is VR supported on the environment
    const canEnterVR = AFRAME.utils.device.checkVRSupport();

    return <Scene xr-mode-ui={canEnterVR ? "enabled: true; enterVRButton: #enterVRButton" : "enabled: false"}
        events={{
            loaded: () => {
                console.log("Scene loaded");
                // @ts-ignore Is VR supported on the environment
                const canEnterVR = AFRAME.utils.device.checkVRSupport();

                const scene = document.querySelector('a-scene');

                if (!scene) {
                    return;
                }

                // Event listeners when switching between desktop and VR mode.
                if (canEnterVR) {
                    scene.addEventListener('enter-vr', () => setIsVrMode(true));
                    scene.addEventListener('exit-vr', () => setIsVrMode(false));
                }

                // Start the house tour.
                startHouseTour();

                sceneLoaded();
            }
        }}
    >

        <AssetsManagement tour={tour} startPanorama={startPanorama} />

        <ViewControl isVrMode={isVrMode} tour={tour} currentPanorama={currentPanorama} changePanorama={changePanorama} />
    </Scene>
}