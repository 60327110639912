import {THREE} from "aframe";
import {HotSpotGlow} from "./HotSpotGlow";
import React from "react";
import {LocationChangeSpot} from "../../api/apiClient";
// @ts-ignore
import {Entity} from 'aframe-react';

export function IconHotSpot(props: {id: string, spot: LocationChangeSpot, onClick: () => void, isVrMode: boolean}) {
    const {id, spot, onClick, isVrMode} = props;

    let material = undefined;
    let geometry = undefined;
    let glowSize = undefined;
    let position = undefined;

    // Icons appear to be too low when in VR mode, moving them slightly up.
    const vrIconLiftAmount = 1;

    switch (spot.type) {
        case "Arrow":
            material = {opacity: 1, side: "double", src: `#arrow-${spot.arrowDirection?.toLowerCase()}`};
            geometry = {height: 0.5, width: 0.5};
            glowSize = 1.2;

            position = isVrMode ? {x: spot.position.x, y: spot.position.y + vrIconLiftAmount, z: spot.position.z} : spot.position;
            break;
        case "Door":
            material = {opacity: 1, side: "double", src: `#door`};
            geometry = {height: 0.5, width: 0.5};
            glowSize = 1;
            position = isVrMode ? {x: spot.position.x, y: spot.position.y + vrIconLiftAmount, z: spot.position.z} : spot.position;
            break;
        default:
            return <></>;
    }

    return <Entity id={id} key={id}
                   position={position}
                   primitive={"a-image"}
                   proxy-event__showglow={`event: mouseenter; to: #glow-${id}; as: show`}
                   proxy-event__hideglow={`event: mouseleave; to: #glow-${id}; as: hide`}
                   proxy-event__fade={`event: click; to: #sky; as: fade`}
                   events={{click: onClick}}
                   class="raycastable panoramaHotspot"
                   geometry={geometry}
                   material={material}
                   look-at={"#camera"}
    >
        {/*The glow is a transparent image which is shown when mouse is hovering over the hotspot.*/}
        <HotSpotGlow id={id} width={glowSize} height={glowSize} position={new THREE.Vector3(0, -0.01, -0.01)} />
    </Entity>
}