import {RoomImage} from "../../../api/apiClient";
import {ImageList, Paper, useMediaQuery, useTheme} from "@mui/material";
import {BrowserRoomImage} from "./BrowserRoomImage";
import React from "react";
import {launchPanoramaChangeAnimations, stopHouseTour} from "../../common";

export interface BrowserRoomMenuProps {
    rooms: RoomImage[];
    currentPanorama: string;
    changePanorama: (panorama: string) => void;
}

export function BrowserRoomMenu(props: BrowserRoomMenuProps) {
    const {rooms, currentPanorama, changePanorama} = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const maxHeight = window.innerHeight -(isVerySmallScreen ? 150 : 50);

    return <Paper elevation={3} className={"fade-in"}
                  sx={{p: 1, paddingTop: 0, m: 2,
                      maxHeight: maxHeight, overflowY: "auto",
                      zIndex: 1050, position: "relative",
                      flexGrow: 1
            }}>
            <ImageList variant={"standard"} cols={isSmallScreen ? 1 : 3} gap={10}>
                {rooms.map(room => <BrowserRoomImage key={"room-image-" + room.targetPanorama} room={room} isCurrent={room.targetPanorama === currentPanorama}
                                                     onClick={(clickedImage) => {
                                                         launchPanoramaChangeAnimations();
                                                         stopHouseTour();

                                                         setTimeout(() => changePanorama(clickedImage), 700);
                                                     }}
                />)}
            </ImageList>
        </Paper>
}