import {Floorplan, FloorplanHotspot, UnrealFloorPlan, UnrealViewPoint} from "./apiClient";
import {THREE} from "aframe";

type Dimensions = {
    width: number;
    height: number;
};

export async function buildFloorPlanData(floorPlan: UnrealFloorPlan, viewPoints: UnrealViewPoint[], contentUrl: string,
                                         imageFolder: string | undefined, imageExtension: string | undefined): Promise<Floorplan> {

    const imageUrl = `${contentUrl}/${imageFolder ? imageFolder + "/" : "floors/"}${floorPlan.Name}.${imageExtension ?? "jpg"}`;

    if (!floorPlan.ViewTilt) {
        floorPlan.ViewTilt = 0;
    }

    const dimensions = await getImageDimensions(imageUrl);

    // View points in this floor.
    const currentFloorViewPoints = viewPoints.filter((vp) => vp.Floor === floorPlan.Name);

    // Build the hotspots
    const hotSpots = currentFloorViewPoints.reduce<FloorplanHotspot[]>((hotspots: FloorplanHotspot[], vp: UnrealViewPoint) =>
        {
            const hotSpotPosition = calculateHotSpotImagePosition(vp, floorPlan, dimensions, 90);

            // Do not add hotspot if viewpoint out of floor image bounds.
            if (hotSpotPosition) {
                hotspots.push({
                    ...hotSpotPosition,
                    targetPanorama: vp.Name.replace(" ", "_"),
                    targetCameraRotation: vp.LookAtDirection.X
                });
            }

            return hotspots;
        }, []);

    return {
        name: floorPlan.Name,
        image: imageUrl,
        imageHeader: floorPlan.Name,
        imageHeight: dimensions.height,
        imageWidth: dimensions.width,
        hotspots: hotSpots,
        imageTilt: floorPlan.ViewTilt
    }
}

// Validate that the hotspot is inside the image bounds. Rotate hotspot position if the image was tilted. Transform the coordinates to image pixel format (zero point at top-left corner)
export function calculateHotSpotImagePosition(viewPoint: UnrealViewPoint, floorPlan: UnrealFloorPlan, dimensions: Dimensions, cameraRotationCorrectionDegrees?: number) {

    const rotatedPointInImagePixels = getPointScreenLocation(
        new THREE.Vector3(viewPoint.Location.X, viewPoint.Location.Y, viewPoint.Location.Z),
        new THREE.Vector2(floorPlan.ViewCenter.X, floorPlan.ViewCenter.Y),
        floorPlan.ViewWidth,
        floorPlan.ViewTilt,
        dimensions.width,
        dimensions.height,
        cameraRotationCorrectionDegrees
    );

    // Validate that the hotspot is in the area of the floorplan image.
    if (rotatedPointInImagePixels.x < 0 ||
        rotatedPointInImagePixels.x > dimensions.width ||
        rotatedPointInImagePixels.y < 0 ||
        rotatedPointInImagePixels.y > dimensions.height
    )
    {
        return undefined;
    }

    return rotatedPointInImagePixels;
}

function getPointScreenLocation(
    pointWorldLocation: THREE.Vector3,
    viewCenterWorldLocation: THREE.Vector2,
    viewWorldWidth: number,
    viewRotationDegrees: number,
    imageSizeX: number,
    imageSizeY: number,
    cameraRotationCorrectionDegrees: number = 180
) {
    // 1. Translate world point relative to the mini-map's center
    const translatedPoint = new THREE.Vector2(
        pointWorldLocation.x - viewCenterWorldLocation.x,
        pointWorldLocation.y - viewCenterWorldLocation.y
    );

    // 2. Convert the rotation angle to radians and apply 2D rotation around the center
    const rotationRadians = THREE.MathUtils.degToRad(cameraRotationCorrectionDegrees - viewRotationDegrees);
    const cosAngle = Math.cos(rotationRadians);
    const sinAngle = Math.sin(rotationRadians);

    const rotatedPoint = new THREE.Vector2();
    rotatedPoint.x = translatedPoint.x * cosAngle - translatedPoint.y * sinAngle;
    rotatedPoint.y = translatedPoint.x * sinAngle + translatedPoint.y * cosAngle;

    // 3. Scale the rotated point to the mini-map's screen size
    const viewWorldHeight = (viewWorldWidth * imageSizeY) / imageSizeX; // Adjust for aspect ratio
    const scaledPoint = new THREE.Vector2(
        (rotatedPoint.x / viewWorldWidth) * imageSizeX,
        (rotatedPoint.y / viewWorldHeight) * imageSizeY
    );

    // 4. Map the scaled point to pixel coordinates with the origin at the top-left corner
    const pointScreenLocation = new THREE.Vector2(
        Math.round(imageSizeX * 0.5 - scaledPoint.x),
        Math.round(imageSizeY * 0.5 - scaledPoint.y)
    );

    return pointScreenLocation;
}

// Fetch the dimensions of the image
function getImageDimensions(url: string): Promise<Dimensions> {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };

        img.onerror = (error) => {
            reject(error);
        };

        img.src = url;
    });
}