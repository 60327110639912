import glow from "../../images/ui/glow.png";
import React from "react";
// @ts-ignore
import {Entity} from 'aframe-react';
import {THREE} from "aframe";


export function HotSpotGlow(props: {id: string, width: number, height: number, position: THREE.Vector3}) {
    const {id, width, height, position} = props;

    return <Entity primitive="a-image" key={"glow-" + id} id={"glow-" + id} class="hotspotglow" position={position}
            material={{src: glow, side: "double", opacity: 0}}
            geometry={{primitive: "plane", height: height, width: width}}
            animation__show="property: material.opacity; type: number; easing: linear; from: 0; to: 0.5; dur: 300; startEvents: show"
            animation__hide="property: material.opacity; type: number; easing: linear; from: 0.5; to: 0; dur: 300; startEvents: hide"
    />
}