import {Entity, THREE} from "aframe";
import {Vector3} from "three";

export function launchPanoramaChangeAnimations(zoomTowards?: Vector3) {
    const sky = document.querySelector('a-sky');
    const cameraRig = document.querySelector('#cameraRig');
    const scene = AFRAME.scenes[0];

    var spots = Array.prototype.slice.call(scene.querySelectorAll('.panoramaHotspot'));

    // Hide hotspots when animation starts
    spots.forEach(function (spot) {
        spot.object3D.visible = false;
    });

    // Emit the fade event.
    sky.emit("fade", null, false);

    // If panorama hotspot was clicked, zoom towards it.
    if (zoomTowards) {
        cameraRig.emit("zoomTowards", {zoomTo: zoomTowards}, false);
    }
    // If menu was used, reset the camera so that user looks at the default direction of the panorama.
    else {
        resetCamera();
    }
}

// Reset camera to point at the default direction.
export function resetCamera() {
    // Get camera rig and camera
    const camera = document.querySelector('#camera');
    const cameraRig = document.querySelector('#cameraRig');

    // Since we can't rotate camera, need to rotate the rig. We must take into consideration the rotation of the camera.
    // Subtract camera rotation from full circle to get the wanted new rotation.
    const newRotationVertical = Math.PI * 2 - camera.object3D.rotation.y;

    // Rotate the camera rig.
    cameraRig.object3D.rotation.set(0, newRotationVertical, 0);
}

export function isVRMode() {
    const scene = document.querySelector("a-scene");

    return scene && scene.is('vr-mode');
}


// Move entity to the front of the camera.
export function bringToFront(entity: Entity) {

    const position = entity.object3D.position // the reference to our position
    const camera = entity.sceneEl!.camera // the reference to the THREE.Camera
    const front = new THREE.Vector3(0, 0, -1);
    camera.localToWorld(front);
    position.copy(front) // use it as the position

    entity.object3D.position.set(position.x, position.y, position.z);
}

export function startHouseTour() {
    document.querySelector('#cameraRig').emit("startTour", null, false);
}

export function exitFullscreen() {
    // Verify that canvas is fullscreen.
    const canvas = document.querySelector(".a-canvas");
    if (document.fullscreenElement === canvas) {
        // Exit fullscreen.
        document.exitFullscreen();
    }
}

export function stopHouseTour() {
    const camera = document.querySelector('#cameraRig');
    camera.emit("pauseTour", null, false);
}

export function calculateLinearInterpolation(input: number, minInput: number, maxInput: number, minOutput: number, maxOutput: number, reverse: boolean = false) {
    let output: number;

    if (minInput === maxInput || minOutput === maxOutput) {
        throw new Error("Same max and min values... seriously?");
    }

    if (input < minInput) {
        output = maxOutput;
    }
    else if (input > maxInput) {
        output = minOutput;
    }
    else {
        if (reverse) {
            // Normalize the input to a value between 0 and 1
            const normalizedInput = (input - minInput) / (maxInput - minInput);

            // Perform reversed linear interpolation within the output range
            output = maxOutput - normalizedInput * (maxOutput - minOutput);
        }
        else {
            // Perform linear interpolation within the output range
            output = minOutput + (input - minInput) / (maxInput - minInput) * (maxOutput - minOutput);
        }
    }

    return output;
}