import {UnrealVector} from "./apiClient";
import {THREE} from "aframe";

export function calculateRotation(viewPointLookAtDirection: UnrealVector) {

    // Default direction
    const initialDirection = new THREE.Vector3(1, 0, 0);
    // Direction of view point camera
    const targetDirection = new THREE.Vector3(viewPointLookAtDirection.X, viewPointLookAtDirection.Z, viewPointLookAtDirection.Y).normalize();
    const quaternion = new THREE.Quaternion();
    // Calculate difference
    quaternion.setFromUnitVectors(initialDirection, targetDirection);

    const euler = new THREE.Euler().setFromQuaternion(quaternion);

    // Convert radians to degrees
    const degrees = {
        x: THREE.MathUtils.radToDeg(euler.x), // Rotation around X-axis
        y: THREE.MathUtils.radToDeg(euler.y), // Rotation around Y-axis
        z: THREE.MathUtils.radToDeg(euler.z)  // Rotation around Z-axis
    };

    return degrees;
}